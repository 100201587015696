<ngb-carousel >
  <ng-template ngbSlide>
    <img src="../../../assets/images/banner-about-us.png" style="height: auto; width: 100%;"alt="Random first slide">
    <div class="carousel-caption">
      <h3></h3>
    
    </div>
  </ng-template>
</ngb-carousel>
<div class="starter-template">
    <h1>Our Policy</h1>
</div>
<div class="container">
  <div class="row">
  <p>
      <strong>RETURN AND REFUND POLICY:</strong><br> 
      The items are 100% as described on listing page, for multiple units there may be a slight
      variation like country of manufacture, style of serials etc., which does not affect the
      authenticity/functionality of the equipment.   
  </p>
  <p>
    We maintain list of serial number of the product, please do not exchange the product
and return it. There will be no refund given.
Insurance is the responsibility of the buyer, please indicate if you are self-insured or
otherwise, need insurance for your goods.
Please do not purchase if you feel that the description is incorrect. Please contact us
before purchasing and we will assist you in making the right purchase.
  </p>
  <p>
      <strong>WARRANTY AND RETURNS:</strong><br>
    Our warranty does not cover customer’s misuse of the product due to negligence or lack
of operating knowledge. All returns will be checked for defects or damages before
replacement or refunds can be issues.
We are accepting returns of unused products within 90 days. However, in order to
maintain good product condition and inventory we may charge re-stocking-fee up to 20
percent.
  </p>
</div>
</div>
<div class="container">
    <div class="row get-started">
          <h5>Lets Get Started!</h5>
          <p>Questions? <a href="mailto:sales@cablesnoptics.com">Email us</a> or call for a quote: <strong>1 (800) 484-4632</strong></p>

    </div>
</div>
<div class="container">
    <div class="row">
     <div class="cisco-banner-box">
        <div class="col-lg-6">
                <img src="../../../assets/images/buy-from.png">
        </div>
        <div class="col-lg-6">
                <img src="../../../assets/images/optics-and-cables-extensive.png">
        </div>
      
        <!-- <div class="banner-button-box">
                <button class=" btn btn-product">Learn More</button>
        </div> -->
    
     </div>
    </div>
</div>