import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database'; 
import { Observable } from 'rxjs/Observable';
import {QuoteModalComponent} from './../modals/quote-modal/quote-modal.component';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cisco-wireless',
  templateUrl: './cisco-wireless.component.html',
  styleUrls: ['./cisco-wireless.component.css']
})
export class CiscoWirelessComponent implements OnInit {
  ciscowirelessObservable: Observable<any[]>;
  constructor(private db: AngularFireDatabase) { }

  ngOnInit() {
    this.ciscowirelessObservable = this.getwireless('/networkingCisco/wireless/');
  }
  getwireless(listPath): Observable<any[]> {
    return this.db.list(listPath).valueChanges();
  }
  ngAfterViewInit() {
    (document.querySelector('.carousel-control-prev') as HTMLElement).style.display = 'none';
    (document.querySelector('.carousel-control-next') as HTMLElement).style.display = 'none';

}
}
