import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tands',
  templateUrl: './tands.component.html',
  styleUrls: ['./tands.component.css']
})
export class TandsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
