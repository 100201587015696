<!-- Footer -->
<footer class="page-footer font-small teal pt-4">
    <div class="container">
  <!-- Footer Text -->
  <div class="container-fluid text-center text-md-left">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-6 mt-md-0 mt-3">

        <!-- Content -->
        <h5 class="font-weight-bold">Company Headquarters</h5>
        <p><strong>Cables N Optics</strong><br>
            9841 Washingtonian Blvd<br>
            Gaithersburg, MD 20878<br>
            Telephone: (202) 509-4457<br>
            </p>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">

      <!-- Grid column -->
      <div class="col-md-6 mb-md-0 mb-3">

        <!-- Content -->
        <h5 class="font-weight-bold">Links</h5>
        <p class="font-weight-bold"><a routerLink="/policy"> Policy</a><br>
          <a routerLink="/tands">Terms of Service</a><br>
          <a routerLink="/faqs"> FAQ</a>
            </p>

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Text -->

  <!-- Copyright -->
  
  <div class="footer-copyright text-center py-3 font-weight-bold">Cables N Optics © 2018. All Rights Reserved  | Site by 
    <a href="http://www.hacondesign.com" target="_blank"> Hacon Design</a>
  </div>
  <!-- Copyright -->
</div>
</footer>
<!-- Footer -->