<!--The content below is only a placeholder and can be replaced.-->
<div class="container no-padding">
<mega-nav></mega-nav>
  <!-- <app-navbar></app-navbar> -->

  <router-outlet></router-outlet>

  <app-footer></app-footer>
</div>

