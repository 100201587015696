import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    (document.querySelector('.carousel-control-prev') as HTMLElement).style.display = 'none';
    (document.querySelector('.carousel-control-next') as HTMLElement).style.display = 'none';

}
}
