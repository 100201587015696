import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireDatabase } from 'angularfire2/database';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'app';
 
  homeRoute : 'home';
  networkingRoute : 'networking';
  aboutUsRoute : 'about-us';
  ProductListRoute : 'product-list';
}
