import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    (document.querySelector('.carousel-control-prev') as HTMLElement).style.display = 'none';
    (document.querySelector('.carousel-control-next') as HTMLElement).style.display = 'none';

}
}
