import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mega-nav',
  templateUrl: './mega-nav.component.html',
  styleUrls: ['./mega-nav.component.css']
})
export class MegaNavComponent implements OnInit {

  collapsed = true;
  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }
  show = false;
  toggleShow(): void{
    this.show = !this.show;
  }
  showOne = false;
  toggleShowOne(): void{
    this.showOne = !this.showOne;
  }
  constructor() { }

  ngOnInit() {
  }

}
