<nav class="navbar navbar-expand-lg navbar-light bg-custom">
  <a class="navbar-brand" routerLink="/home">
    <img src="../../assets/images/Optics-and-Cables-LOGO-B1.png">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
    aria-expanded="false" aria-label="Toggle navigation" (click)="toggleCollapsed()">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbsarSupportedContent" [ngClass]="{'collapse': collapsed, 'navbar-collapse': true}">
    <ul class="navbar-nav navbar-custom mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="/home">Home</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          (click)="toggleShow()">
          Products
        </a>
        <div class="dropdown-menu hide" aria-labelledby="navbarDropdown" [ngClass]="{'show': show, 'dropdown-menu collapsed': true}">
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <div class="text-uppercase border-bottom title">Networking</div>
                <ul class="nav flex-column">
                  <!-- <li class="nav-item">
                  <a class="nav-link active" routerLink='/home'>Cisco</a>
                </li> -->
                  <li class="nav-item">
                    <div class=" subnav-title">Juniper</div>
                    <ul class="nav flex-column">
                      <li class="">
                        <a class="nav-link " routerLink='/juniper-switches'>Switches</a>
                      </li>
                      <li class="">
                        <a class="nav-link" routerLink='/juniper-routers'>Routers</a>
                      </li>
                    </ul>
                    <hr>
                    <div class=" subnav-title">Cisco</div>
                    <ul class="nav flex-column">
                        <li class="">
                            <a class="nav-link" routerLink='cisco-opticstransceivers'>Optics & Cable Transceivers</a>
                          </li>
                      <li class="">
                        <a class="nav-link " routerLink='cisco-switches'>Switches</a>
                      </li>
                      <li class="">
                        <a class="nav-link" routerLink='cisco-routers'>Routers</a>
                      </li>
                      <li class="">
                        <a class="nav-link " routerLink='cisco-iptelephony'>IP Telephony</a>
                      </li>
                      <li class="">
                        <a class="nav-link" routerLink='cisco-wireless'>Wireless</a>
                      </li>
                      <li class="">
                        <a class="nav-link" routerLink='cisco-legacyproducts'>Legacy Products</a>
                      </li>
                      
                    </ul>
                        <hr>
                         <ul class="nav flex-column">
                            <div class=" subnav-title">Arista</div>
                    <li class="nav-item">
                      <a class="nav-link" routerLink="/arista-products">Arista Products</a>
                    </li>
                  </ul>
                  </li>
                 
                </ul>
              </div>
              <!-- /.col-md-4  -->
              <div class="col-md-4">
                <div class="text-uppercase border-bottom title">Servers</div>
                <div class=" subnav-title">Cisco</div>
                <ul class="nav flex-columnd margin-bottom">
                  <li class="">
                    <a class="nav-link" routerLink="/product-list">Cisco Servers</a>
                  </li>
                  <!-- <li class="nav-item">
                  <a class="nav-link" href="#">Link item</a>
                </li># -->
                  <!-- <li class="nav-item">
                  <a class="nav-link" href="#">Link item</a>
                </li> -->
                </ul>
                <div class="text-uppercase border-bottom title">Featured</div>
                <div class=" subnav-title">Our Products</div>
                <ul class="nav flex-column">
                  <li class="">
                    <a class="nav-link" routerLink="/featured-products">Featured Products</a>
                  </li>
                  <!-- <li class="nav-item">
                  <a class="nav-link" href="#">Link item</a>
                </li># -->
                  <!-- <li class="nav-item">
                  <a class="nav-link" href="#">Link item</a>
                </li> -->
                </ul>
              </div>
              
              <!-- /.col-md-4  -->
              <div class="col-md-4">
                <a href="">

                  <img src="../../assets/images/nav-savings.png" alt="" class="img-fluid">
                </a>
                <p class="text-white"></p>

              </div>
              <!-- /.col-md-4  -->

            </div>
          </div>
          <!--  /.container  -->


        </div>
      </li>
      <li class="nav-item dropdown1">
        <a class="nav-link" routerLink="/about-us">About Us</a>
        <!-- <div class="dropdown-menu" aria-labelledby="navbarDropdown" [ngClass]="{'showOne': showOne, 'dropdown-menu': true}">


          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <span class="text-uppercase text-white">Category 2</span>
                <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link active" href="#">Active</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Link item</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Link item</a>
                </li>
              </ul>
              </div>
            
              <div class="col-md-4">
                <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link active" href="#">Active</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Link item</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Link item</a>
                </li>
              </ul>
              </div>
            
              <div class="col-md-4">
                <a href="">
                  <img src="https://dummyimage.com/200x100/ccc/000&text=image+link" alt="" class="img-fluid">
                </a>
                <p class="text-white">Short image call to action</p>

              </div>
           
            </div>
          </div>
     


        </div> -->
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link" routerLink="/faqs">FAQ</a>
      </li>
      <!-- <li class="nav-item dropdown">
        <a class="nav-link" routerLink="/home">Request a Quote</a>
      </li> -->
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <!-- <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-light my-2 my-sm-0" type="submit">Search</button> -->
      <div class="number">1 (800) 484-4632</div>
      <a href="tel:18004844632">
        <img src="../../assets/images/icons/phone.png" />
      </a>
    </form>
  </div>


</nav>
