import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database'; 
import { Observable } from 'rxjs/Observable';
import {QuoteModalComponent} from './../modals/quote-modal/quote-modal.component';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'featured-products',
  templateUrl: './featured-products.component.html',
  styleUrls: ['./featured-products.component.css']
})
export class FeaturedProductsComponent implements OnInit {
  featuredproductsObservable: Observable<any[]>;
  constructor(private db: AngularFireDatabase) { }

  ngOnInit() {
    this.featuredproductsObservable = this.getfproducts('/featured/products/');
  }
  getfproducts(listPath): Observable<any[]> {
    return this.db.list(listPath).valueChanges();
  }
  ngAfterViewInit() {
    (document.querySelector('.carousel-control-prev') as HTMLElement).style.display = 'none';
    (document.querySelector('.carousel-control-next') as HTMLElement).style.display = 'none';

}
}
