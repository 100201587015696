<ngb-carousel >
    <ng-template ngbSlide>
        <img src="../../assets/images/header/header0.png" style="height: auto; width: 100%;"alt="Random first slide">
        <div class="carousel-caption">
          <!-- <h3>Huge Savings and Deals!</h3>
          <p>Upgrade your network on a budget.</p> -->
          <!-- <p>Get a better return on your investment when buying pre-owned and refurbished Cisco IT hardware when you buy from Cables N Optics. We offer pre-owned, legacy and cur-rent model network equipment
              at great savings!
              </p> -->
        </div>
      </ng-template>
  <ng-template ngbSlide>
    <img src="../../assets/images/header/header.png" style="height: auto; width: 100%;"alt="Random first slide">
    <div class="carousel-caption">
      <h3>Huge Savings and Deals Available with Massive Markdowns!</h3>
      <p>Upgrade your network on a budget.</p>
      <!-- <p>Get a better return on your investment when buying pre-owned and refurbished Cisco IT hardware when you buy from Cables N Optics. We offer pre-owned, legacy and cur-rent model network equipment
          at great savings!
          </p> -->
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <img src="../../assets/images/header/header1.png"  style="height: auto; width: 100%;" alt="Random second slide">
    <div class="carousel-caption">
      <h3>2 Years to Lifetime Warranty Available!</h3>
      <!-- <p></p> -->
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <img src="../../assets/images/header/header2.png"  style="height: auto; width: 100%;" alt="Random third slide">
    <div class="carousel-caption">
      <h3>Need Your Shipment in a Hurry?</h3>
      <p>No problem. We ship worldwide!</p>
    </div>
  </ng-template>
  <!-- <ng-template ngbSlide>
    <img src="../../assets/images/header/header3.png"  style="height: auto; width: 100%;" alt="Random third slide">
    <div class="carousel-caption">
      <h3>Have Equipment to Trade or Sell?</h3>
      <p>Dont Worry. We will trade in or buy your used equipment.</p>
    </div>
  </ng-template> -->
</ngb-carousel>