// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCmEuGOd860ZcW4DcTBoAct8vTc0zcRucg",
    authDomain: "oandc-a9ab7.firebaseapp.com",
    databaseURL: "https://oandc-a9ab7.firebaseio.com",
    projectId: "oandc-a9ab7",
    storageBucket: "oandc-a9ab7.appspot.com",
    messagingSenderId: "683095324745"
  },
  apiKey: "AIzaSyDyC6TrXufzdPc5pMdCGGW6qWGfb6AnLW4",
  authDomain: "request-form-212f0.firebaseapp.com",
  databaseURL: "https://request-form-212f0.firebaseio.com",
  projectId: "request-form-212f0",
  storageBucket: "",
  messagingSenderId: "1065621064027"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
