
<app-header></app-header>
<div class="starter-template">
    <h1>We Carry a Large Inventory of Cables N Optics That are Available for Immediate Delivery.
</h1>
    <p>We will trade or buy your decommissioned equipment.</p>
</div>
<div class="container">
<div class="row">
<div class="col-lg-4">
    <h4>Contact Us</h4>
    <p>For the latest product availability information. 
        If we don't have what you need on hand, chances are we can find it quickly and at a great price!</p>
</div>    
<div class="col-lg-8 form-box">
    <request></request>
</div>
</div>
<div class="container">
    <div class="row">
     <div class="banner-box">
        <div class="col-lg-6">
              
        </div>
        <div class="col-lg-6">
                <img src="../../assets/images/hardware-pre-owned.png">
        </div>
      
        <!-- <div class="banner-button-box">
                <button class=" btn btn-product">Learn More</button>
        </div> -->
    
     </div>
    </div>
</div>
<div class="container">
        <div class="row get-started">
              <h5>Lets Get Started!</h5>
              <p>Questions? <a href="mailto:sales@cablesnoptics.com">Email us</a> or call for a quote: <strong>1 (800) 484-4632</strong></p>

        </div>
</div>
<div class="container">
        <div class="row">
         <div class="cisco-banner-box">
            <div class="col-lg-6">
                    <img src="../../assets/images/buy-from.png">
            </div>
            <div class="col-lg-6">
                    <img src="../../assets/images/optics-and-cables-extensive.png">
            </div>
          
            <!-- <div class="banner-button-box">
                    <button class=" btn btn-product">Learn More</button>
            </div> -->
        
         </div>
        </div>
    </div>
</div>
