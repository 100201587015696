import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AppComponent } from './app.component';
import { environment } from './../environments/environment';
import { AppNavbarComponent } from './app-navbar/app-navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductListComponent } from './product-list/product-list.component';
import { HeaderComponent } from './header/header.component';
import { QuoteModalComponent } from './modals/quote-modal/quote-modal.component';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { RequestFormComponent } from './request-form/request-form.component';
import { FormsModule } from '@angular/forms';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import * as data from '../../assets/countries.json';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { MegaNavComponent } from './mega-nav/mega-nav.component';
import { JuniperRoutersComponent } from './juniper-routers/juniper-routers.component';
import { JuniperSwitchesComponent } from './juniper-switches/juniper-switches.component';
import { SendMailComponent } from './send-mail/send-mail.component';
import { HttpModule } from '@angular/http';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { CiscoRoutersComponent } from './cisco-routers/cisco-routers.component';
import { CiscoSwitchesComponent } from './cisco-switches/cisco-switches.component';
import { CiscoIptelephonyComponent } from './cisco-iptelephony/cisco-iptelephony.component';
import { CiscoWirelessComponent } from './cisco-wireless/cisco-wireless.component';
import { CiscoOpticstransceiversComponent } from './cisco-opticstransceivers/cisco-opticstransceivers.component';
import { CiscoLegacyproductsComponent } from './cisco-legacyproducts/cisco-legacyproducts.component';
import { FeaturedProductsComponent } from './featured-products/featured-products.component';
import { AristaProductsComponent } from './arista-products/arista-products.component';
import { TandsComponent } from './tands/tands.component';
import { PolicyComponent } from './policy/policy.component';
const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'product-list', component: ProductListComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'juniper-switches', component: JuniperSwitchesComponent}, 
  { path: 'juniper-routers', component: JuniperRoutersComponent}, 
  { path: 'cisco-iptelephony', component: CiscoIptelephonyComponent}, 
  { path: 'cisco-routers', component: CiscoRoutersComponent}, 
  { path: 'cisco-switches', component: CiscoSwitchesComponent}, 
  { path: 'cisco-opticstransceivers', component: CiscoOpticstransceiversComponent},
  { path: 'cisco-legacyproducts', component: CiscoLegacyproductsComponent}, 
  { path: 'arista-products', component: AristaProductsComponent},
  { path: 'featured-products', component: FeaturedProductsComponent},
  { path: 'cisco-wireless', component: CiscoWirelessComponent}, 
  { path: 'tands', component: TandsComponent},
  { path: 'policy', component: PolicyComponent} 
 ];
@NgModule({
  declarations: [
    AppComponent,
    AppNavbarComponent,
    ProductListComponent,
    HeaderComponent,
    QuoteModalComponent,
    HomeComponent,
    FooterComponent,
    RequestFormComponent,
    AboutUsComponent,
    FaqsComponent,
    MegaNavComponent,
    JuniperRoutersComponent,
    JuniperSwitchesComponent,
    SendMailComponent,
    CiscoRoutersComponent,
    CiscoSwitchesComponent,
    CiscoIptelephonyComponent,
    CiscoWirelessComponent,
    CiscoOpticstransceiversComponent,
    CiscoLegacyproductsComponent,
    FeaturedProductsComponent,
    AristaProductsComponent,
    TandsComponent,
    PolicyComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true }
    ),
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    HttpModule
  ],
  providers: [
    AngularFireAuth,
    AngularFireDatabase
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  
 }
