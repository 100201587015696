<ngb-carousel >
    <ng-template ngbSlide>
      <img src="../../../assets/images/banner-about-us.png" style="height: auto; width: 100%;"alt="Random first slide">
      <div class="carousel-caption">
        <h3></h3>
      
      </div>
    </ng-template>
  </ngb-carousel>
  <div class="starter-template">
      <h1>Terms of Service</h1>
  </div>
  <div class="container">
    <div class="row">
    <p>
        <strong>TERMS AND CONDITIONS OF SALE:</strong><br> 
        We provide standard 2 YEARS warranty for our end-user customers extendable to
        lifetime and 90 days for dealers. All claims on packages that have been damaged during
        shipping must be reported within 3 business day of item(s) being delivered. We process
        DOA (Dead On Arrival) equipment immediately for replacements (First 7 Days). We will
        exchange non-working units if there are identical units in stock. Otherwise, refund will
        be issued minus shipping/handling/insurance costs.
    </p>

  </div>
  </div>
  <div class="container">
      <div class="row get-started">
            <h5>Lets Get Started!</h5>
            <p>Questions? <a href="mailto:sales@cablesnoptics.com">Email us</a> or call for a quote: <strong>1 (800) 484-4632</strong></p>
  
      </div>
  </div>
  <div class="container">
      <div class="row">
       <div class="cisco-banner-box">
          <div class="col-lg-6">
                  <img src="../../../assets/images/buy-from.png">
          </div>
          <div class="col-lg-6">
                  <img src="../../../assets/images/optics-and-cables-extensive.png">
          </div>
        
          <!-- <div class="banner-button-box">
                  <button class=" btn btn-product">Learn More</button>
          </div> -->
      
       </div>
      </div>
  </div>