<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Request Quote</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <request></request>
            <!-- <div class="container">
                <div class="form-group text">
                    <label for="company">Company</label><br>
                    <input name="company" type="text" id="company">
                    <span class="help-block">You must select a program</span>
                </div>
                <div class="form-group text">
                    <label for="name">Name<span class="required">*</span></label><br>
                    <input name="name" type="text" id="name" class="required-entry">
                    <span class="help-block">This information is required.</span>
                </div>
                <div class="form-group text">
                    <label for="email">Email<span class="required">*</span></label><br>
                    <input name="email" type="text" id="email" class="required-entry validate-email">
                </div>
                <div class="form-group text">
                    <label for="phone">Phone<span class="required">*</span></label><br>
                    <input name="phone" type="text" id="phone" class="required-entry">
                    <span class="help-block">This information is required.</span>
                </div>
                <div class="form-group">
                    <label for="program">Condition</label><br>
                    <ul>
                        <li><input name="condition[]" type="checkbox" id="New" value="New"> <label for="New">New</label>
                        </li><li><input name="condition[]" type="checkbox" id="Refurbished" value="Refurbished">
                            <label for="Refurbished">Refurbished</label>
                    </li></ul>
                </div>
            </div>
          <label for="dateOfBirth">Date of birth</label>
          <div class="input-group">
            <input id="dateOfBirth" class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #dp="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"></button>
            </div>
          </div> -->
        </div>
      </form>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Submit Request</button>
    </div> -->
  </ng-template>
  
  <button class="btn btn-product" (click)="open(content)">Request Quote</button>
  
  <!-- <pre>{{closeResult}}</pre> -->