
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireDatabase } from 'angularfire2/database';
import * as data from '../../assets/countries.json';
import { ReactiveFormsModule } from '@angular/forms';
// import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'request',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.css']
})
export class RequestFormComponent implements OnInit {

  form: FormGroup;
  // public countries: any;

  public countries = (<any>data).countries; ;
  
  constructor(private fb: FormBuilder, private af: AngularFireDatabase) {
    this.createForm();
    
  }
  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators],
      company: ['', Validators],
      country: ['', Validators],
      interest: ['', Validators],
      message: ['', Validators.required],
    });
  }
  onSubmit() {
    const { name, email, phone, company, country, interest, message } = this.form.value;
    const date = Date();
    const html = `
      <div>From: ${name}</div>
      <div>Email: <a href="mailto:${email}">${email}</a></div>      
      <div>Date: ${date}</div>
      <div>Phone: ${phone}</div>
      <div>Company: ${company}</div>
      <div>Country: ${country}</div>
      <div>Interested In: ${interest}</div>
      <div>Message: ${message}</div>      

    `;
    let formRequest = { name, email, phone, company, interest, message, date, html };
    this.af.list('/quotes').push(formRequest);
    this.form.reset();
  }
  ngOnInit() {
      
  }


}
