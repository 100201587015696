<ngb-carousel>
  <ng-template ngbSlide>
    <img src="../../../assets/images/banner-faqs.png" style="height: auto; width: 100%;" alt="Random first slide">
    <div class="carousel-caption">
      <h3>FAQ</h3>

    </div>
  </ng-template>
</ngb-carousel>
<div class="starter-template">
  <h1>Frequently Asked Questions</h1>
</div>
<div class="container">
  <div class="row">
    <ngb-accordion [closeOthers]="true" activeIds="static-1">
      <ngb-panel id="static-1" title="What are the benefits of purchasing pre-owned equipment?">
        <ng-template ngbPanelContent>
          Cables N Optics offers 30-90% cost off manufacturers list prices with the same peace of mind that comes with it. Most manufacturers
          offer 1-year limited warranty while we offer 2 years to a lifetime warranty on all products purchased through our
          website. We trust our process, and so should you. </ng-template>
      </ngb-panel>
      <ngb-panel id="static-2">
        <ng-template ngbPanelTitle>
          <span>What type of warranty does Cables N optics offer on equipment it sells?</span>
        </ng-template>
        <ng-template ngbPanelContent>
          Cables N Optics understands that networks are mission-critical for any business, and our warranty is designed with uptime
          in mind. Depending on the product, Optics & Cables offers warranties ranging from 2 years to a lifetime on all
          pre-owned manufacturer-branded equipment. For as long as you own the equipment, if you experience an equipment
          failure, our technical engineering support teams will either solve your problem over the phone or depending on
          availability, immediately send a replacement unit for next-business-day delivery.
        </ng-template>
      </ngb-panel>
      <ngb-panel id="static-3" title="What are the differences between “pre-owned”, “refurbished” and “used” equip-ment?" [disabled]="false">
        <ng-template ngbPanelContent>
          Cables N Optics considers all equipment it sells as “pre-owned,” meaning originally it was sold by the manufacturer or an
          authorized reseller and now is being resold. Some of our inventory is used and some equipment has been resold without
          being used. All Cables N Optics’s used equipment has undergone full inspection, cleaning, testing and reconditioning
          to return the equipment to as close to original condition as possible. In some cases, equipment requires minor
          repairs or cosmetic improvement, and is then designated as “refurbished”. Our goal is to return equipment to “like
          new” condition while offering exceptional savings of between 50 and 90 percent off manufacturers’ list prices.
        </ng-template>
      </ngb-panel>
      <!-- <ngb-panel id="static-4">
        <ng-template ngbPanelTitle>
          <span>What type of warranty does Cables N Optics provide on the equipment it sells?</span>
        </ng-template>
        <ng-template ngbPanelContent>
          Cables N Optics understands that networks are mission critical for any business, and our warranty is designed with uptime
          in mind. Depending on the product, Optics & Ca-bles offers warranties ranging from 90 days, 1-2 years and even
          a lifetime warranty on all pre-owned manufacturer-branded equipment. For as long as you own the equip-ment, if
          you experience equipment failure, our technical engineering support teams will either solve your problem over the
          phone or immediately send a replacement unit for next-business-day delivery.
        </ng-template>
      </ngb-panel> -->
      <ngb-panel id="static-5">
        <ng-template ngbPanelTitle>
          <span>What types of equipment are available on the secondary market?</span>
        </ng-template>
        <ng-template ngbPanelContent>
          Today, almost any kind of server, storage and networking equipment can be found for sale on the secondary market. Cables
          N Optics’s inventory is typically driven by current market demand, so the company aggressively pursues the top
          products in order to pro-vide customers with the most popular equipment models. With its extensive resources and
          contacts throughout many vertical markets, Cables N Optics is typically able to de-liver even specialized or hard-to-find
          items.
        </ng-template>
      </ngb-panel>
      <ngb-panel id="static-6">
        <ng-template ngbPanelTitle>
          <span>Where does Cables N Optics get its equipment?</span>
        </ng-template>
        <ng-template ngbPanelContent>
          When a company upgrades their environment, cancels a project or closes a location, Cables N Optics will buy its surplus equipment
          or take it as credit toward a purchase. We also source equipment from systems integrators who may purchase gear
          for a cli-ent only to see the project cancelled or changed, as well as other used equipment re-sellers.
        </ng-template>
      </ngb-panel>
      <ngb-panel id="static-7">
        <ng-template ngbPanelTitle>
          <span>Is counterfeit a big problem? How does Cables N Optics handle it?</span>
        </ng-template>
        <ng-template ngbPanelContent>
          There is counterfeit equipment in the market, particularly with dominant brands like Cisco, but Cables N Optics has seen
          counterfeiting decrease in recent years. In the ef-fort to eradicate counterfeit equipment, Cables N Optics has
          a strict methodology for identifying suspect equipment, quarantining it for detailed inspection, documenting evolving
          counterfeiting techniques. We maintain a comprehensive knowledge base used by our quality technicians globally
          to ensure that all equipment is authentic and meets factory specifications. Awareness among resellers and customers
          is the best way to fight counterfeiters.
        </ng-template>
      </ngb-panel>
      <!-- <ngb-panel id="static-8">
                        <ng-template ngbPanelTitle>
                          <span>Is counterfeit a big problem? How does Cables N Optics handle it?</span>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            There is counterfeit equipment in the market, particularly with dominant brands like Cisco, but Cables N Optics has seen counterfeiting decrease in recent years. In the ef-fort to eradicate counterfeit equipment, Cables N Optics has a strict methodology for identifying suspect equipment, quarantining it for detailed inspection, documenting evolving counterfeiting techniques. We maintain a comprehensive knowledge base used by our quality technicians globally to ensure that all equipment is authentic and meets factory specifications. Awareness among resellers and customers is the best way to fight counterfeiters.
                        </ng-template>
                      </ngb-panel> -->
      <ngb-panel id="static-9">
        <ng-template ngbPanelTitle>
          <span>What makes Cables N Optics the most reliable and trusted supplier of preowned equipment?</span>
        </ng-template>
        <ng-template ngbPanelContent>
          Cables N Optics is an industry leader in pre-owned and new equipment and a strategic partner to hundreds of organizations
          advanced hardware replacement and complete lifecycle management of networking and data centre equipment. Our hardware
          goes through a 40-point inspection and bench testing, including real network testing before they ship to our customers.
          Cables N Optics have a counterfeit zero-tolerance policy. We provide organizations with only quality and reliable
          networking and data centre equipment solutions.
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>
<div class="container">
  <div class="row get-started">
    <h5>Lets Get Started!</h5>
    <p>Questions?
      <a href="mailto:sales@cablesnoptics.com">Email us</a> or call for a quote:
      <strong>1 (800) 484-4632
      </strong>
    </p>

  </div>
</div>
<div class="container">
  <div class="row">
    <div class="cisco-banner-box">
      <div class="col-lg-6">
        <img src="../../../assets/images/buy-from.png">
      </div>
      <div class="col-lg-6">
        <img src="../../../assets/images/optics-and-cables-extensive.png">
      </div>

      <!-- <div class="banner-button-box">
                  <button class=" btn btn-product">Learn More</button>
          </div> -->

    </div>
  </div>
</div>