import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database'; 
import { Observable } from 'rxjs/Observable';
import {QuoteModalComponent} from './../modals/quote-modal/quote-modal.component';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'juniper-routers',
  templateUrl: './juniper-routers.component.html',
  styleUrls: ['./juniper-routers.component.css']
})
export class JuniperRoutersComponent implements OnInit {

  juniperRoutersObservable: Observable<any[]>;
  constructor(private db: AngularFireDatabase) { }

  ngOnInit() {
    this.juniperRoutersObservable = this.getJuperRouters('/networkingJuniper/routers/');
    
  }
  getJuperRouters(listPath): Observable<any[]> {
    return this.db.list(listPath).valueChanges();
  }
  ngAfterViewInit() {
    (document.querySelector('.carousel-control-prev') as HTMLElement).style.display = 'none';
    (document.querySelector('.carousel-control-next') as HTMLElement).style.display = 'none';

}
}
