<ngb-carousel >
    <ng-template ngbSlide>
      <img src="../../assets/images/banner-servers.png" style="height: auto; width: 100%;"alt="Random first slide">
      <!-- <div class="carousel-caption">
        <h3>Switches</h3>
      </div> -->
    </ng-template>
  </ngb-carousel>
  <div class="starter-template">
      <h1>Juniper Switches</h1>
  </div>
  <!-- <div class="container">
      <div class="row top-product">
      <p>
          <strong>Used Products</strong> - Tested & Guaranteed
          We carry an expansive switch selection that provides the highest levels of speed and availability, for the best price for your budget! For optimal user-level LAN access, ex-tending the life of network infrastructure, or expanding of a campus area network, our Cisco Router selection caters to your business or organization's specialized needs.
             
      </p>

    </div>
    </div> -->
    <!-- <div class="container">
      <h5>Switches</h5>
    </div> -->
<div class="container item-custom">
<ul class="flex" class="row">
  <div *ngFor="let switch of juniperSwitchesObservable | async" class="col-lg-4 col-md-6 margerine-bottom">
      <div class="item-box" >
          <h2>{{switch.name}}</h2>
 
        <div class="image-box"> 
          <img src="{{switch.imgUrl}}" />
        </div>        
       
       
          <div>
            <app-quote-modal></app-quote-modal>
       
          </div>
        </div> 
  </div>
</ul>
</div>
<div class="container">
    <div class="row get-started">
          <h5>Lets Get Started!</h5>
          <p>Questions? <a href="mailto:sales@cablesnoptics.com">Email us</a> or call for a quote: <strong>1 (800) 484-4632</strong></p>

    </div>
</div>
