<h2>Request Quote</h2>
<form  [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="container no-padding">
  <div class="col-lg-6 col-sm-12 left pad-right">
  <div class="form-group">
  <input class="form-control" type="text" placeholder="Name" formControlName="name">
</div>
<div class="form-group">
  <input type="text" class="form-control" placeholder="Email" formControlName="email">
  </div>
  <div class="form-group">
          <input type="text" class="form-control" placeholder="Phone" formControlName="phone">
          </div>
  <div class="form-group">
      <input type="text" class="form-control" placeholder="Company" formControlName="company">
      </div>
      
    </div>
    <!-- end 1st col -->
    <div class="col-lg-6 col-sm-12 left no-padding">
        <div class="form-group">
            <select id="inputState" class="form-control" >
                <option selected >Choose Country...</option>
                <option *ngFor="let country of countries" >{{country.name}}</option>
              </select>
        </div>
        <div class="form-check form-group">
          <div class="col-lg-6">
            <input class="form-check-input" type="checkbox" id="gridCheck1"  formControlName="interest" >
            <label class="form-check-label" for="gridCheck1">
              Buying
            </label>
          </div>
          <div class="col-lg-6">
            <input class="form-check-input" type="checkbox" id="gridCheck1" formControlName="interest">
            <label class="form-check-label" for="gridCheck1">
             Selling
            </label>
          </div>
          </div>
  <div class="form-group">
  <textarea class="form-control" placeholder="Message" formControlName="message">
    </textarea>
    </div>
    
      </div>
      </div>
      
      <div class="container-btn no-padding">
  <button class="btn btn-product float-right" type="submit"  [disabled]="form.invalid">Submit Request</button>
      </div>
</form>      
<!-- <li *ngFor="let country of countries">
        <p>{{country.name}}</p>
</li> -->

<!--for closing modal (click)="modal.close('Save click')" -->