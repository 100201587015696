<ngb-carousel >
    <ng-template ngbSlide>
      <img src="../../../assets/images/banner-about-us.png" style="height: auto; width: 100%;"alt="Random first slide">
      <div class="carousel-caption">
        <h3>About Us</h3>
      
      </div>
    </ng-template>
  </ngb-carousel>
  <div class="starter-template">
      <h1>The Cables N Optics Promise</h1>
  </div>
  <div class="container">
    <div class="row">
    <p>
        <strong>Cables N Optics</strong> is a leader in pre-owned network and data center equipment.
        At Cables N Optics, we have a veritable combination of system engineers dedicated to helping you leverage the best your network has to offer while making sure you stay within your budget. <br><br>
        We also identify “best fit for you” options by extending the life of IT assets and providing significant cost-savings on multi-vendor platforms with minimal disruption to existing IT environments. Through our robust portfolio of products, we help customers navigate the complex IT web of new and emerging tech-nologies in a cloud dominated space.   
    </p>
  </div>
  </div>
  <div class="container">
      <div class="row get-started">
            <h5>Lets Get Started!</h5>
            <p>Questions? <a href="mailto:sales@cablesnoptics.com">Email us</a> or call for a quote: <strong>1 (800) 484-4632</strong></p>

      </div>
</div>
<div class="container">
      <div class="row">
       <div class="cisco-banner-box">
          <div class="col-lg-6">
                  <img src="../../../assets/images/buy-from.png">
          </div>
          <div class="col-lg-6">
                  <img src="../../../assets/images/optics-and-cables-extensive.png">
          </div>
        
          <!-- <div class="banner-button-box">
                  <button class=" btn btn-product">Learn More</button>
          </div> -->
      
       </div>
      </div>
  </div>